<template>
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <c-table
        ref="vendorUserTable"
        title="도급업체 근무인원"
        tableId="vendorUserTable"
        :columns="grid.columns"
        :data="grid.data"
        :usePaging="false"
        :hideBottom="true"
        :columnSetting="false"
        selection="multiple"
        rowKey="vendorUserCd"
        @table-data-change="tableDataChange"
      >
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn v-if="editable && this.parentVendorCd" label="추가" icon="add" @btnClicked="addrow" />
            <c-btn v-if="editable && this.parentVendorCd" label="삭제" icon="remove" @btnClicked="removerow" />
            <c-btn v-if="editable && this.parentVendorCd" label="저장" icon="save" @btnClicked="saveVendorUser" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'vendor-user',
  props: {
    parentVendorCd: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selectedVendorCd: '',
      grid: {
        columns: [],
        data: [],
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  watch: {
    parentVendorCd() {
      if (this.parentVendorCd) {
        this.getVendorUserList({ vendorCd: this.parentVendorCd })
      } else {
        this.grid.data = [];
      }
    }
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.mdm.cim.vendor.user.list.url;
      this.saveUrl = transactionConfig.mdm.cim.vendor.user.save.url;
      this.deleteUrl = transactionConfig.mdm.cim.vendor.user.delete.url;

      this.$comm.getComboItems('SEX_CD').then(sexFlag => {
        this.grid.columns = [
          {
            name: 'personName',
            field: 'personName',
            label: '성명',
            align: 'center',
            sortable: false,
            style: 'width:100px',
            type: 'text',
          },
          {
            name: 'birthDate',
            field: 'birthDate',
            label: '생년월일',
            style: 'width:120px',
            type: 'date',
            align: 'center',
            sortable: false,
          },
          {
            name: 'sexCd',
            field: 'sexCd',
            label: '성별',
            align: 'center',
            style: 'width:65px',
            sortable: false,
            valueName: 'codeName',
            itemText: 'codeName',
            itemValue: 'code',
            type: 'select',
            comboItems: sexFlag,
          },
          {
            name: 'jobName',
            field: 'jobName',
            label: '직책',
            style: 'width:100px',
            type: 'text',
            align: 'center',
            sortable: false,
          },
          {
            name: 'dailyEmployFlag',
            field: 'dailyEmployFlag',
            label: '일용직여부',
            style: 'width:50px',
            type: 'check',
            'true': 'Y',
            'false': 'N',
            align: 'center',
            sortable: false,
          },
          {
            name: 'employDate',
            field: 'employDate',
            label: '근무기간',
            style: 'width:200px',
            align: 'center',
            type: 'date',
            range: true,
            sortable: false,
          },
          {
            name: 'educationFlag',
            field: 'educationFlag',
            label: '안전교육이수여부',
            align: 'center',
            sortable: false,
            child: [
              {
                name: 'industrySafetyEdcationFlag',
                field: 'industrySafetyEdcationFlag',
                label: '산업',
                style: 'width:55px',
                type: 'check',
                'true': 'Y',
                'false': 'N',
                align: 'center',
                sortable: false,
              },
              {
                name: 'constructionSafetyEdcationFlag',
                field: 'constructionSafetyEdcationFlag',
                label: '건설',
                style: 'width:55px',
                type: 'check',
                'true': 'Y',
                'false': 'N',
                align: 'center',
                sortable: false,
              },
            ]
          },
          {
            name: 'remarks',
            field: 'remarks',
            label: '비고',
            align: 'left',
            type: 'text',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'attach',
            field: 'attach',
            label: '이수증 첨부파일',
            align: 'center',
            style: 'width:200px',
            type: 'attach',
            taskClassCd: 'VENDOR_USER_EDCATION',
            keyText: 'vendorUserCd',
            sortable: false,
          }
        ];
        if (this.parentVendorCd) {
          this.getVendorUserList({ vendorCd: this.parentVendorCd })
        }
      });
    },
    getVendorUserList(row) {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {vendorCd: row.vendorCd};
      this.$http.request((_result) => {
        if (_result.data && _result.data.length > 0) {
          this.$_.forEach(_result.data, item => {
            this.$set(item, 'employDate', [item.employStartDate, item.employEndDate])
          })
        }
        this.grid.data = _result.data;
      },);
    },
    tableDataChange(props, col) {
      if (col.name === 'employDate' && props.row[col.name] && props.row[col.name].length > 0) {
        this.$set(props.row, 'employStartDate', props.row[col.name][0] ? props.row[col.name][0] : '')
        this.$set(props.row, 'employEndDate', props.row[col.name][1] ? props.row[col.name][1] : '')
      }
    },
    addrow() {
      // 초기에는 userId에 tempId를 부여
      this.grid.data.splice(0, 0, {
        editFlag: 'C',
        vendorCd: this.parentVendorCd,
        vendorUserCd: uid(),
        personName: '',
        birthDate: '',
        sexCd: null,
        jobName: '',
        dailyEmployFlag: 'N',
        employStartDate: '',
        employEndDate: '',
        employDate: [],
        industrySafetyEdcationFlag: 'N',
        constructionSafetyEdcationFlag: 'N',
        remarks: '',
      })
    },
    saveVendorUser() {
      let checkItem = ['personName', 'sexCd']
      let isConti = true;
      this.$_.forEach(this.grid.data, item => {
        this.$_.forEach(checkItem, check => {
          if (!item[check]) {
            isConti = false;
            return false;
          }
        })
      });
      if (!isConti) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '필수 입력값을 입력해 주세요. [성명, 성별]',
          type: 'warning', // success / info / warning / error
        });
      } else {
        let saveData = this.grid.data.filter( x => {
          return x.editFlag == 'C' || x.editFlag == 'U'
        });
        if(saveData.length > 0) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            type: 'info',
            // 확인 callback 함수
            confirmCallback: () => {
              this.$http.url = this.saveUrl;
              this.$http.type = 'POST';
              this.$http.param = saveData;
              this.$http.request(() => {
                window.getApp.$emit('APP_REQUEST_SUCCESS');
                this.getVendorUserList({ vendorCd: this.parentVendorCd })
              });
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('ALERT', {
            title: '안내 ', /* 안내 */
            message: '변경된 데이터가 없습니다.', /* 변경된 데이터가 없습니다. */
            type: 'info', // success / info / warning / error
          });
        }
      }
    },
    removerow() {
      let selectData = this.$refs['vendorUserTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.deleteUrl);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // this.getVendorUserList({ vendorCd: this.parentVendorCd })
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.grid.data = this.$_.reject(this.grid.data, item);
              })
              this.$refs['vendorUserTable'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
  }
};
</script>
